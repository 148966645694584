<script setup lang="ts">
import { adminAuth, type User } from '@/api/auth'
import type { Rule } from 'ant-design-vue/es/form'
import { type RendererElement, reactive, ref } from 'vue'
import { message } from 'ant-design-vue'
import { useAccountStore } from '@/stores/user'

interface AdminAuthModel {
  id: string
  name: string
  secret: string
}

const accountStore = useAccountStore()
const { login } = accountStore

const open = ref(false)
const authLoading = ref(false)
const showAdminAuth = () => {
  open.value = true
}
const doAdminAuth = () => {
  authLoading.value = true
  adminAuthFormRef.value
    ?.validate()
    .then(() => {
      adminAuth(
        {
          id: parseInt(adminAuthInfo.id),
          name: adminAuthInfo.name,
          avatarUrl: ''
        } as User,
        adminAuthInfo.secret
      ).then(
        async (resp) => {
          // 设定user，并关闭弹窗
          await login(resp.data)
          authLoading.value = false
          open.value = false
          message.success('管理员认证成功')
        },
        (err) => {
          authLoading.value = false
          message.error(err.response?.data.message || '管理员认证失败')
        }
      )
    })
    .catch(() => {
      authLoading.value = false
    })
}

const adminAuthFormRef = ref<RendererElement>()
const adminAuthInfo = reactive<AdminAuthModel>({
  id: '',
  name: '系统管理员-0',
  secret: import.meta.env.VITE_ADMIN_SECRET_KEY
})
const adminAuthRules: Record<string, Rule[]> = {
  id: [
    { required: true, message: '必须指定管理员id', trigger: 'change' },
    {
      validator: (rule, value) => {
        if (!value) {
          return Promise.resolve()
        } else if (isNaN(value)) {
          return Promise.reject(new Error('管理员id必须为数字'))
        } else {
          const id = parseInt(value)
          if (id > 0) {
            return Promise.reject(new Error('管理员id必须小于0'))
          }
          return Promise.resolve()
        }
      },
      trigger: 'change'
    }
  ],
  name: [
    { required: true, message: '必须指定管理员用户名', trigger: 'change' },
    { min: 3, max: 10, message: '管理员用户名长度必须在3-10之间', trigger: 'blur' }
  ],
  secret: [{ required: true, message: '必须指定管理员secret', trigger: 'change' }]
}
</script>

<template>
  <a-flex :justify="'center'" :align="'center'" style="height: 100%">
    <a-button @click="showAdminAuth" ghost>管理员认证</a-button>
    <a-modal
      :open="open"
      :confirm-loading="authLoading"
      title="管理员认证"
      @ok="doAdminAuth"
      @cancel="open = false"
    >
      <a-form
        ref="adminAuthFormRef"
        :model="adminAuthInfo"
        :rules="adminAuthRules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-item label="id" name="id">
          <a-input v-model:value="adminAuthInfo.id" placeholder="请指定管理员id" />
        </a-form-item>
        <a-form-item label="name" name="name">
          <a-input
            v-model:value="adminAuthInfo.name"
            placeholder="请指定管理员用户名"
            show-count
            :maxlength="10"
          />
        </a-form-item>
        <a-form-item required label="secret" name="secret">
          <a-input v-model:value="adminAuthInfo.secret" placeholder="请指定管理员秘钥" />
        </a-form-item>
      </a-form>
    </a-modal>
  </a-flex>
</template>

<style scoped lang="less"></style>
