<script lang="ts" setup>
import {
  createOrg,
  renewOrg,
  upgradeOrg,
  type Organization,
  type OrganizationReq
} from '@/api/org/basic'
import type { PaymentOrder } from '@/api/payment'
import { useConfigStore } from '@/stores/config'
import {
  FormOutlined,
  LinkOutlined,
  PayCircleOutlined,
  RiseOutlined,
  SmileOutlined,
  StepForwardOutlined
} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { storeToRefs } from 'pinia'
import { computed, h, ref, type PropType, type RendererElement } from 'vue'
import { useRouter } from 'vue-router'
import type { PaymentInfo } from '.'
import OrgInfoForm from '../org/OrgInfoForm.vue'
import OrgRenewForm from '../org/OrgRenewForm.vue'
import OrgUpgradeForm from '../org/OrgUpgradeForm.vue'
import WechatPay from './WechatPay.vue'

type PurposeType = 'create' | 'renew' | 'upgrade'
const prop = defineProps({
  purpose: {
    type: String as PropType<PurposeType>,
    required: true
  },
  org: {
    type: Object as PropType<Organization>,
    required: false
  }
})
const emit = defineEmits(['payOk', 'withoutPayOk', 'close'])

const router = useRouter()
const configStore = useConfigStore()
const { planMap } = storeToRefs(configStore)

const withoutPay = ref(false)
const paySuccess = ref(false)
const current = ref<number>(0)
const successTitle = computed(() => {
  if (prop.purpose === 'renew') {
    return '续订服务成功！'
  } else if (prop.purpose === 'upgrade') {
    return '版本升级成功！'
  } else {
    return '团队创建成功！'
  }
})

const fullSteps = [
  { title: '填写团队信息', icon: h(FormOutlined), componentName: 'OrgInfoForm' },
  { title: '服务续订', icon: h(StepForwardOutlined), componentName: 'OrgRenew' },
  { title: '升级版本', icon: h(RiseOutlined), componentName: 'OrgUpgrade' },
  { title: '线上支付', icon: h(PayCircleOutlined), componentName: 'WechatPay' },
  { title: '完成', icon: h(SmileOutlined), componentName: '' }
]
const steps = computed(() => {
  if (prop.purpose === 'renew') {
    return [fullSteps[1], fullSteps[3], fullSteps[4]]
  } else if (prop.purpose === 'upgrade') {
    return [fullSteps[2], fullSteps[3], fullSteps[4]]
  } else if (withoutPay.value) {
    return [fullSteps[0], fullSteps[4]]
  } else {
    return [fullSteps[0], fullSteps[3], fullSteps[4]]
  }
})

const formRef = ref<RendererElement>()
const renewRef = ref<RendererElement>()
const upgradeRef = ref<RendererElement>()
const orgInfo = ref<OrganizationReq>()
const paymentInfo = ref<PaymentInfo>()
const paymentOrder = ref<PaymentOrder>()
const amount = ref(0)
const orderNo = ref('')

const toPayStep = async () => {
  // 分别执行不同的操作
  if (prop.purpose === 'create') {
    orgInfo.value = await formRef.value?.validateOrgInfo()
    if (!orgInfo.value) {
      // 说明校验失败
      return
    }
    // 检查是否有支付部分
    if (!withoutPay.value) {
      paymentInfo.value = await formRef.value?.validatePaymentInfo()
      if (!paymentInfo.value) {
        // 说明校验失败
        return
      }
      const { version, month } = paymentInfo.value
      const plan = planMap.value.get(version)
      if (!plan) {
        message.error('当前服务版本不存在')
      }
      amount.value = plan!!.pricePerMonth * month
    }
    current.value = 1
  } else if (prop.purpose === 'renew') {
    // 续订服务需要获取续订时长
    if (!prop.org) {
      message.error('团队信息不存在')
    }
    paymentInfo.value = await renewRef.value?.validateInfo()
    if (!paymentInfo.value) {
      // 说明校验失败
      return
    }
    const plan = planMap.value.get(prop.org!!.serviceVersion)
    if (!plan) {
      message.error('当前服务版本不存在')
    }
    amount.value = plan!!.pricePerMonth * paymentInfo.value.month
    current.value = 1
  } else {
    // 升级版本需要获取升级到的版本和时长
    if (!prop.org) {
      message.error('团队信息不存在')
    }
    paymentInfo.value = await upgradeRef.value?.validateInfo()
    if (!paymentInfo.value) {
      // 说明校验失败
      return
    }
    const { version, amount: payAmount } = paymentInfo.value
    const plan = planMap.value.get(version)
    if (!plan) {
      message.error('要升级的版本不存在')
    } else if (typeof payAmount !== 'number') {
      message.error('支付金额不正确')
    }
    amount.value = payAmount!!
    current.value = 1
  }
}
const toPrevStep = () => {
  current.value--
  paySuccess.value = false
}

const handleFree = () => {
  withoutPay.value = true
}
const handleCharge = () => {
  withoutPay.value = false
}

const emitClose = () => {
  emit('close')
}
const toOrgPage = () => {
  emitClose()
  router.push('/org')
}
const toProjectPage = () => {
  emitClose()
  router.push({ name: 'project-manage' })
}

const handlePaySuccess = async (order?: PaymentOrder) => {
  // 检查团队信息
  if (!orgInfo.value) {
    orgInfo.value = await formRef.value?.validateOrgInfo()
    if (!orgInfo.value) {
      // 说明校验失败
      return
    }
  }
  // 分别执行不同的操作
  if (prop.purpose === 'create') {
    // 创建团队
    try {
      if (order) {
        paymentOrder.value = order
        amount.value = order.amount
        orderNo.value = order.orderNo
        orgInfo.value.paymentOrderNo = order.orderNo
        emit('payOk', order)
      } else {
        emit('withoutPayOk')
      }
      await createOrg(orgInfo.value)
      current.value++
    } catch (err: any) {
      message.error(err.response?.data.message || '团队创建失败')
    }
  } else if (prop.purpose === 'renew') {
    // 续订服务
    if (!order) {
      message.error('续订服务失败，必须提供订单信息')
      return
    }
    try {
      paymentOrder.value = order
      amount.value = order.amount
      orderNo.value = order.orderNo
      paymentInfo.value!!.paymentOrderNo = order.orderNo
      emit('payOk', order)
      const { month } = paymentInfo.value!!
      await renewOrg(prop.org!!.id, month, order.orderNo)
      current.value++
    } catch (err: any) {
      message.error(err.response?.data.message || '续订服务失败')
    }
  } else {
    // 升级版本
    if (!order) {
      message.error('升级版本失败，必须提供订单信息')
      return
    }
    try {
      paymentOrder.value = order
      amount.value = order.amount
      orderNo.value = order.orderNo
      paymentInfo.value!!.paymentOrderNo = order.orderNo
      emit('payOk', order)
      const { month, version } = paymentInfo.value!!
      await upgradeOrg(prop.org!!.id, month, version, order.orderNo)
      current.value++
    } catch (err: any) {
      message.error(err.response?.data.message || '升级版本失败')
    }
  }
}
</script>

<template>
  <div class="steps-container">
    <a-steps class="steps-steps" :current="current" :items="steps" size="small" />
    <div class="steps-content">
      <template v-if="current === 0">
        <org-info-form
          ref="formRef"
          @free="handleFree"
          @charge="handleCharge"
          v-if="purpose === 'create'"
        />
        <org-renew-form :org="org!!" ref="renewRef" v-if="purpose === 'renew'" />
        <org-upgrade-form :org="org!!" ref="upgradeRef" v-if="purpose === 'upgrade'" />
      </template>
      <template v-else-if="current === steps.length - 1">
        <a-result status="success" :title="successTitle">
          <template #subTitle>
            <p v-if="!withoutPay">支付成功！订单编号：{{ orderNo }}</p>
            <p>感谢您的支持！接下来您可以</p>
          </template>
        </a-result>
      </template>
      <template v-else-if="current > 0 && current < steps.length - 1">
        <wechat-pay :amount="amount" @success="handlePaySuccess" />
      </template>
    </div>
    <div class="steps-action">
      <a-space size="middle">
        <a-button type="primary" @click="toPayStep" v-if="!withoutPay && current === 0">
          去支付
        </a-button>
        <a-button
          type="primary"
          @click="handlePaySuccess()"
          v-if="withoutPay && current === 0 && purpose === 'create'"
        >
          创建
        </a-button>
        <template v-if="paySuccess && current === steps.length - 1">
          <a-button @click="toOrgPage">
            <template #icon><LinkOutlined /></template>
            去邀请成员
          </a-button>
          <a-button @click="toProjectPage">
            <template #icon><LinkOutlined /></template>
            去创建项目
          </a-button>
          <a-button @click="emitClose">关闭窗口</a-button>
        </template>
        <a-button
          @click="toPrevStep"
          v-if="!paySuccess && current > 0 && current < steps.length - 1"
        >
          上一步
        </a-button>
      </a-space>
    </div>
  </div>
</template>

<style lang="less" scoped>
.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    width: 70%;
    padding: 18px 0;
  }

  .steps-steps {
    border-bottom: 1px solid #e9e9e9;
  }

  .steps-content {
    width: 60%;
    padding-bottom: 0;
  }

  .steps-action {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #e9e9e9;
  }
}
</style>
