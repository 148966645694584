<script lang="ts" setup>
import type { Organization } from '@/api/org/basic'
import PayAmountDetail from '@/components/org/PayAmountDetail.vue'
import { useConfigStore } from '@/stores/config'
import type { Rule } from 'ant-design-vue/es/form'
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'
import { computed, reactive, ref, watchEffect, type PropType, type RendererElement } from 'vue'
import { monthOptions } from '.'
import type { PaymentInfo } from '../payment'

const prop = defineProps({
  org: {
    type: Object as PropType<Organization>,
    required: true
  }
})
defineExpose({
  validateInfo: () => {
    return new Promise((resolve, reject) => {
      formRef.value?.validate().then(
        () => {
          resolve(renewInfo)
        },
        () => reject(null)
      )
    })
  }
})

const formRef = ref<RendererElement>()
const renewInfo = reactive<PaymentInfo>({
  orgId: prop.org.id,
  month: 1,
  version: 'std',
  amount: 0
})
const renewRules: Record<string, Rule[]> = reactive({
  month: [{ required: true, message: '请选择续订时长', trigger: 'change' }]
})

const configStore = useConfigStore()
const { systemPricingConfig } = storeToRefs(configStore)
const currentPlan = computed(() => {
  if (!systemPricingConfig.value) {
    return null
  }
  return systemPricingConfig.value.plans.find((plan) => plan.code === prop.org.serviceVersion)
})

watchEffect(() => {
  renewInfo.orgId = prop.org.id
  renewInfo.version = prop.org.serviceVersion
  onMonthChange()
})
const onMonthChange = () => {
  renewInfo.amount = (currentPlan.value?.pricePerMonth ?? 0) * renewInfo.month
}

const payAmount = computed<number>(() => renewInfo.amount ?? 0)
const totalAmount = computed<number>(() => payAmount.value)
const expireTime = computed<dayjs.Dayjs>(() => dayjs().add(renewInfo.month, 'month'))
</script>

<template>
  <a-form
    ref="formRef"
    :model="renewInfo"
    :rules="renewRules"
    :label-col="{ span: 4 }"
    :wrapper-col="{ span: 20 }"
  >
    <a-form-item label="选择续订时长" name="month" required>
      <a-radio-group
        v-model:value="renewInfo.month"
        option-type="button"
        :options="monthOptions"
        @change="onMonthChange"
      />
    </a-form-item>
    <a-form-item label="费用详情" name="detail">
      <pay-amount-detail
        :pay-amount="payAmount"
        :total-amount="totalAmount"
        :expire-time="expireTime"
      />
    </a-form-item>
  </a-form>
</template>

<style lang="less" scoped></style>
