import request from '@/utils/request'
import dayjs from 'dayjs'
import { MicroSvrAddrs } from '../common/config'
import type { DataResponse, OperationResponse, QueryResponse } from '../common/entity'
import { dataListTransformer, singleDataTransformer } from '../common/utils'
import type { PaymentOrderType } from '../payment'
import type { SystemPricingType } from '../system/config'

export interface OrgVersionHistoryPO {
  id: number
  orgId: number
  serviceVersion: SystemPricingType
  active: boolean
  startTime: Date
  inputStartTime: Date
  endTime?: Date
  totalAmount: number
  deductAmount: number
  payAmount: number
  type: PaymentOrderType
  paymentOrderId: string
  creatorId: number
  createTime: Date
}

const orgVerHisTransformer = (data: any): OrgVersionHistoryPO => {
  data.startTime = dayjs.unix(data.startTime).toDate()
  data.inputStartTime = dayjs.unix(data.inputStartTime).toDate()
  data.endTime = data.endTime ? dayjs.unix(data.endTime).toDate() : undefined
  data.createTime = dayjs.unix(data.createTime).toDate()
  return data as OrgVersionHistoryPO
}

const singleOrgVerHisResponseTransformer = singleDataTransformer(orgVerHisTransformer)

const orgVerHisListTransformer = dataListTransformer(orgVerHisTransformer)

export enum OrgStatus {
  TRIAL = 'TRIAL', // 试用
  FORMAL = 'FORMAL', // 正式
  DISSOLVED = 'DISSOLVED' // 已解散
}

export const OrgStatusMap = new Map<OrgStatus, string>([
  [OrgStatus.TRIAL, '试用'],
  [OrgStatus.FORMAL, '正式'],
  [OrgStatus.DISSOLVED, '已解散']
])

export const OrgVersionColorMap = new Map<SystemPricingType, string>([
  ['fre', '#7cbb00'], // green
  ['std', '#1677ff'], // blue
  ['adv', '#722ed1'], // purple
  ['pro', '#faad14'] // gold
])

export interface Organization {
  id: number
  parentId: number
  name: string
  description?: string
  status: OrgStatus
  ownerId: number
  workTimeInHours: number
  workTimeStart: string
  workTimeEnd: string
  serviceVersion: SystemPricingType
  serviceExpireTime?: Date
  createTime: Date
  updatedTime?: Date
}

const orgRequestTransformer = (data: any): Organization => {
  const startArr = data.workTimeStart as Array<number | string>
  data.workTimeStart = `${startArr[0]}:${startArr[1].toString().padStart(2, '0')}${startArr[2]}`
  const endArr = data.workTimeEnd as Array<number | string>
  data.workTimeEnd = `${endArr[0]}:${endArr[1].toString().padStart(2, '0')}${endArr[2]}`
  data.createTime = dayjs.unix(data.createTime).toDate()
  data.updatedTime = data.updatedTime ? dayjs.unix(data.updatedTime).toDate() : undefined
  return data as Organization
}

const singleOrgResponseTransformer = singleDataTransformer(orgRequestTransformer)

const orgListResponseTransformer = dataListTransformer(orgRequestTransformer)

// 查询可见团队列表
export async function fetchOrgList(): Promise<QueryResponse<Organization>> {
  return request.get(`${MicroSvrAddrs.taskania}/org`, {
    transformResponse: orgListResponseTransformer
  })
}

// 查询团队详情
export async function fetchOrgDetail(id: number): Promise<DataResponse<Organization>> {
  return request.get(`${MicroSvrAddrs.taskania}/org/${id}`, {
    transformResponse: singleOrgResponseTransformer
  })
}

// 查询团队版本历史列表
export async function fetchOrgVersionHistoryList(
  orgId: number,
  pageSize: number,
  pageNo: number
): Promise<QueryResponse<OrgVersionHistoryPO>> {
  return request.get(`${MicroSvrAddrs.taskania}/org/${orgId}/history`, {
    params: { pageSize, pageNo },
    transformResponse: orgVerHisListTransformer
  })
}

export interface OrganizationReq {
  name: string
  description?: string
  status: OrgStatus
  workTimeInHours: number
  workTimeStart: string
  workTimeEnd: string
  serviceVersion: SystemPricingType
  paymentOrderNo?: string
}

// 创建团队
export async function createOrg(req: OrganizationReq): Promise<OperationResponse<number>> {
  return request.post(`${MicroSvrAddrs.taskania}/org`, req)
}

// 更新团队信息
export async function updateOrg(
  id: number,
  req: OrganizationReq
): Promise<OperationResponse<number>> {
  return request.put(`${MicroSvrAddrs.taskania}/org/${id}`, req)
}

// 解散团队
export async function dissolveOrg(id: number): Promise<OperationResponse<number>> {
  return request.delete(`${MicroSvrAddrs.taskania}/org/${id}`)
}

// 团队续费当前版本
export async function renewOrg(
  id: number,
  months: number,
  paymentOrderNo: string
): Promise<OperationResponse<number>> {
  return request.put(
    `${MicroSvrAddrs.taskania}/org/${id}/renew`,
    { paymentOrderNo },
    { params: { months } }
  )
}

// 团队升级版本
export async function upgradeOrg(
  id: number,
  month: number,
  version: SystemPricingType,
  paymentOrderNo: string
): Promise<OperationResponse<number>> {
  return request.put(
    `${MicroSvrAddrs.taskania}/org/${id}/upgrade/${version}`,
    { paymentOrderNo },
    { params: { month } }
  )
}

export interface OrgUpgradeInfo {
  orgId: number
  months: number
  serviceVersion: SystemPricingType
  endTime: Date
  totalAmount: number
  deductAmount: number
  payAmount: number
  deductDays: number
}

const orgUpgradeInfoTransformer = (data: any): OrgUpgradeInfo => {
  data.endTime = dayjs.unix(data.endTime).toDate()
  return data as OrgUpgradeInfo
}

const singleOrgUpgradeInfoTransformer = singleDataTransformer(orgUpgradeInfoTransformer)

const orgListUpgradeInfoTransformer = dataListTransformer(orgUpgradeInfoTransformer)

// 获取团队升级版本信息（包括所需支付金额，当前余额可抵扣天数等）
export async function fetchOrgUpgradeInfo(
  orgId: number,
  months: number,
  serviceVersion: SystemPricingType
): Promise<DataResponse<OrgUpgradeInfo>> {
  return request.get(`${MicroSvrAddrs.taskania}/org/${orgId}/upgrade/${serviceVersion}/info`, {
    params: { months },
    transformResponse: singleOrgUpgradeInfoTransformer
  })
}
