import request from '@/utils/request'
import { MicroSvrAddrs } from '../common/config'
import type { CustomDict, DataResponse, QueryResponse } from '../common/entity'

// 系统配置实体
export interface SystemConfig<T> {
  id: number //  配置ID
  type: string //  配置类型
  item: string //  配置项
  value: T //  配置项值
  description: string //  配置描述
  orderNo: number //  排序号
}

// 查询系统配置列表
export async function querySystemConfigList<T = any>(
  types: string[]
): Promise<QueryResponse<SystemConfig<T>>> {
  return request.get(`${MicroSvrAddrs.taskania}/sys/config`, {
    params: {
      types: types.join(',')
    }
  })
}

// 查询系统配置项
export async function querySystemConfigItem<T>(
  type: string,
  item: string
): Promise<DataResponse<SystemConfig<T>>> {
  return request.get(`${MicroSvrAddrs.taskania}/sys/config/${type}/${item}`)
}

interface NameAndUrl {
  name: string
  url: string
}

export interface SystemIndexConfig {
  guides: {
    video: String
    guide: NameAndUrl[]
  }
  login: CustomDict<string>
  links: {
    groupName: string
    groupLinks: NameAndUrl[]
  }[]
  contacts: {
    desc: string
    kf: boolean
    pic: string
  }[]
}

export const CONFIG_TYPE_SYSTEM = 'system'
export const CONFIG_ITEM_SYSTEM_INDEX = 'index'
export const CONFIG_ITEM_SYSTEM_PRICING = 'pricing'
export const CONFIG_ITEM_SYSTEM_GUIDE = 'guide'

export type SystemPricingType = 'fre' | 'std' | 'adv' | 'pro'
export const SystemPricingTypes: SystemPricingType[] = ['fre', 'std', 'adv', 'pro']

export interface PricePlanInfo {
  name: string
  code: SystemPricingType
  desc: string
  pricePerMonth: number
  statistics: boolean
  highlights: string[]
}
export interface SystemPricingConfig {
  plans: PricePlanInfo[]
  comparisons: {
    group: string
    features: ({
      name: string
      type: 'Y/N' | 'NUM' | 'TXT' | 'SYS'
      desc?: string
    } & Record<SystemPricingType, number | string | any>)[]
  }[]
}

export interface SystemGuideConfig {
  [key: string]: {
    doc: string
    video: string
  }
}

export type ConfigPath = string
export type ValNull = null
export type ValY = true
export type ValN = false
export type ValNum = number
export type ValUnlimited = -1
export type ValTxt = string
export type ValOther = undefined
export type FeatureVal = ValNull | ValY | ValN | ValNum | ValUnlimited | ValTxt | ValOther
export type FeatureConfigMap = Map<ConfigPath, FeatureVal>

export type FeatureKey =
  | 'PricePerMonth'
  | 'StatisticsApis'
  | 'OrgEmployeeCount'
  | 'WechatNotice'
  | 'CloseProject'
  | 'ReopenProject'
  | 'ProjectCount'
  | 'ProjectTaskCount'
  | 'RoleCount'
  | 'MultiRole'
  | 'TaskTypeCount'
  | 'ReqPoolSize'
  | 'ApplyCreateReq'
  | 'ReqAttCount'
  | 'ReqAttSizeText'
  | 'ReqAttSizeKb'
  | 'ReqLifecycle'
  | 'RevReqPartial'
  | 'RevPartial'
  | 'BatchCount'
  | 'BatchPause'
  | 'Milestone'
  | 'TaskMultiExec'
  | 'TaskMultiRole'
  | 'TaskDepProject'
  | 'TaskDepOrg'
  | 'TaskEstUpdate'
  | 'TaskExecHistory'
  | 'TaskAtt'
  | 'TaskAttCount'
  | 'TaskAttSizeText'
  | 'TaskAttSizeKb'
  | 'TaskTempCount'
  | 'TaskReqReq'
  | 'PlanIntervalMin'
export const FEATURE_CONFIG_PATH_KEYS: Record<ConfigPath, FeatureKey> = {
  price_per_month: 'PricePerMonth', ///
  statistics_apis: 'StatisticsApis', ///
  '团队&成员_成员人数': 'OrgEmployeeCount', ///
  '团队&成员_微信通知': 'WechatNotice', ///
  项目控制_关闭项目: 'CloseProject', ///
  项目控制_重启项目: 'ReopenProject', ///
  项目控制_项目数量限制: 'ProjectCount', ///
  项目控制_总任务数: 'ProjectTaskCount', // ------TODO------
  项目管理_角色数量: 'RoleCount', ///
  项目管理_参与者多角色: 'MultiRole', ///
  项目管理_任务类型数量: 'TaskTypeCount', ///
  需求_需求池容量: 'ReqPoolSize', ///
  需求_根据需求申请创建需求: 'ApplyCreateReq', ///
  需求_需求附件数量: 'ReqAttCount', ///
  需求_需求附件大小: 'ReqAttSizeText', ///
  需求_req_att_size_kb: 'ReqAttSizeKb', ///
  需求_需求生命周期: 'ReqLifecycle', ///
  需求修订_申请部分修订: 'RevReqPartial', ///
  需求修订_部分修订需求: 'RevPartial', ///
  进度控制_需求批次数量: 'BatchCount', ///
  进度控制_批次中断执行: 'BatchPause', // ------TODO------
  进度控制_里程碑管理: 'Milestone', ///
  任务_多人任务: 'TaskMultiExec', ///
  任务_多角色任务: 'TaskMultiRole', ///
  任务_前置任务为本项目下任务: 'TaskDepProject', ///
  任务_前置任务为本团队内任务: 'TaskDepOrg', ///
  '任务_更新任务的所需执行人、角色或工时': 'TaskEstUpdate', ///
  任务_任务执行历史: 'TaskExecHistory', ///
  任务_上传任务附件: 'TaskAtt', ///
  任务_任务附件数量: 'TaskAttCount', ///
  任务_任务附件大小: 'TaskAttSizeText', ///
  任务_task_att_size_kb: 'TaskAttSizeKb', ///
  任务_临时任务数量: 'TaskTempCount', ///
  任务_申请将临时任务转化为需求: 'TaskReqReq', ///
  任务调度_plan_interval_min: 'PlanIntervalMin' ///
}
