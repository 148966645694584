<script setup lang="ts">
import HeaderAccount from '@/components/account/HeaderAccount.vue'
import AdminAuth from '@/components/auth/AdminAuth.vue'
import HeaderMessage from '@/components/message/HeaderMessage.vue'
import OrgSwitch from '@/components/org/OrgSwitch.vue'
import OrgPaymentSteps from '@/components/payment/OrgPaymentSteps.vue'
import WithCompareModal from '@/components/payment/WithCompareModal.vue'
import HeaderUserTodo from '@/components/user/HeaderUserTodo.vue'
import HeaderWorkRecord from '@/components/user/HeaderWorkRecord.vue'
import { useConfigStore } from '@/stores/config'
import { useOrgStore } from '@/stores/org'
import { useAccountStore } from '@/stores/user'
import { FileTextOutlined, PlaySquareOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue'
import { type MenuProps } from 'ant-design-vue'
import { storeToRefs } from 'pinia'
import { computed, h, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const accountStore = useAccountStore()
const { user } = storeToRefs(accountStore)

const menuItems = ref<MenuProps['items']>([
  {
    key: 'home',
    label: '首页'
  },
  {
    key: 'workbench',
    label: '工作台'
  },
  // {
  //   key: 'book',
  //   label: h('a', { href: 'https://www.baidu.com', target: '_blank' }, '文章')
  // },
  {
    key: 'issue',
    label: h('a', { href: 'https://gitee.com', target: '_blank' }, '问题反馈')
  },
  {
    key: 'pricing',
    label: '产品定价'
  }
])
const hideFooter = computed(() => {
  return route.name !== 'home' && route.name !== 'pricing'
})

const selectedKeys = computed<string[]>(() => {
  if (!route.name || route.name === 'index' || route.name === 'home') {
    return ['home']
  } else if (route.name === 'pricing') {
    return ['pricing']
  } else {
    return ['workbench']
  }
})
const clickMenu = (item: any) => {
  if (item.key === 'home' && route.name !== '/') {
    router.push('/')
  } else if (item.key === 'pricing' && route.name !== 'pricing') {
    router.push('/pricing')
  } else if (item.key === 'workbench' && !route.path.startsWith('/workbench')) {
    router.push('/workbench')
  }
}

const showAdminAuth = ref(false)

const handleChangeOrg = (orgId: number) => {
  console.log('emit团队切换', orgId)
  if (route.name !== 'workbench') {
    if (route.path.startsWith('/workbench')) {
      // 此时回到工作台页面
      // TODO 若各个下属页面都已经应对了团队变化的情况，则此时不需要处理，暂时先这样，后续如果需要改动，可以参考WorkbenchView.vue中的处理
      router.push({ name: 'workbench' })
    }
    // 此时暂时不需要作任何处理
  } else {
    // 此时需要刷新页面
    // 工作台页面已经自行处理，这里不需要处理
    // router.go(0)
  }
}

const configStore = useConfigStore()
const { systemGuideConfig } = storeToRefs(configStore)
const guideInfo = computed(() => {
  return systemGuideConfig.value && systemGuideConfig.value[route.name as string]
})

const orgStore = useOrgStore()
const { org, renewModalOpen, upgradeModalOpen } = storeToRefs(orgStore)
const { hideRenewModal, renewSuccess, hideUpgradeModal, upgradeSuccess } = orgStore
</script>

<template>
  <a-layout class="common-layout-container">
    <a-layout-header class="common-layout-header">
      <a-row style="height: 100%">
        <a-col :span="1" style="height: 100%">
          <RouterLink :to="{ name: 'index' }">
            <img
              alt="Taskania-专注于软件开发的任务管理系统"
              class="logo"
              src="@/assets/img/taskania.svg"
            />
          </RouterLink>
        </a-col>
        <a-col :span="7">
          <a-menu
            theme="dark"
            mode="horizontal"
            class="header-menu"
            :items="menuItems"
            :selectedKeys="selectedKeys"
            @click="clickMenu"
          />
        </a-col>
        <a-col :span="2">
          <admin-auth v-if="showAdminAuth && !user" />
        </a-col>
        <a-col :span="4">
          <div
            style="
              color: #fff;
              cursor: default;
              font-size: 36px;
              font-weight: 700;
              font-style: italic;
              text-align: center;
            "
            @click.ctrl.alt.space="showAdminAuth = true"
          >
            Taskania
          </div>
        </a-col>
        <a-col :span="10">
          <a-flex :justify="'flex-end'" :align="'center'" :gap="'large'" style="height: 100%">
            <org-switch v-if="user" @change="handleChangeOrg" />
            <header-user-todo v-if="user" />
            <header-work-record v-if="user" />
            <header-message v-if="user" />
            <header-account />
          </a-flex>
        </a-col>
      </a-row>
    </a-layout-header>
    <a-layout-content class="common-layout-content" :class="{ 'hide-footer': hideFooter }">
      <div
        class="content-inner-div"
        :class="{ 'content-inner-div-workbench': route.name === 'workbench' }"
      >
        <RouterView />
      </div>
      <!-- 悬浮按钮 -->
      <a-float-button-group
        trigger="hover"
        :style="{ right: '24px', bottom: '24px' }"
        v-if="guideInfo"
      >
        <template #icon>
          <QuestionCircleOutlined />
        </template>
        <a-float-button
          tooltip="帮助文档"
          :href="guideInfo.doc"
          target="_blank"
          v-if="guideInfo.doc"
        >
          <template #icon>
            <FileTextOutlined />
          </template>
        </a-float-button>
        <a-float-button
          tooltip="视频介绍"
          :href="guideInfo.video"
          target="_blank"
          v-if="guideInfo.video"
        >
          <template #icon>
            <PlaySquareOutlined />
          </template>
        </a-float-button>
      </a-float-button-group>
      <!-- 续费、升级弹窗 -->
      <template v-if="org">
        <with-compare-modal
          :open="upgradeModalOpen"
          title="升级版本"
          content-title="填写升级信息"
          @close="hideUpgradeModal"
        >
          <org-payment-steps
            purpose="upgrade"
            :org="org"
            @close="hideUpgradeModal"
            @pay-ok="upgradeSuccess"
            @without-pay-ok="upgradeSuccess"
          />
        </with-compare-modal>
        <with-compare-modal
          :open="renewModalOpen"
          title="服务续订"
          content-title="填写续订信息"
          @close="hideRenewModal"
        >
          <org-payment-steps
            purpose="upgrade"
            :org="org"
            @close="hideRenewModal"
            @pay-ok="renewSuccess"
            @without-pay-ok="renewSuccess"
          />
        </with-compare-modal>
      </template>
    </a-layout-content>
  </a-layout>
</template>

<style lang="less">
.ant-comment .ant-comment-inner {
  padding: 0;
}
</style>

<style lang="less" scoped>
.logo {
  height: 64px;
  padding: 12px 0px;
}

.common-layout-container {
  position: fixed;
  z-index: 1;
  width: 100%;
}

.common-layout-header {
  background-color: #007d00;
}

.header-menu {
  line-height: 64px;
  background-color: #007d00;
}

.common-layout-content {
  height: calc(100vh - 64px);
  background-color: #f5f5f5;
  &.hide-footer {
    padding: 0 16px;
    & > .content-inner-div {
      padding: 16px 0;
    }
  }
}

.content-inner-div {
  height: 100%;
  overflow: hidden auto;

  &.content-inner-div-workbench {
    @media screen and (max-height: 900px) {
      margin-right: -16px;
    }
  }
}
</style>
