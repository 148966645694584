import { useOrgStore } from '@/stores/org'

export const monthOptions = [
  { label: '1个月', value: 1 },
  { label: '2个月', value: 2 },
  { label: '3个月', value: 3 },
  { label: '6个月', value: 6 },
  { label: '9个月', value: 9 },
  { label: '12个月', value: 12 }
]

export type BadgeMode = 'cover' | 'text' | 'none'
export type ForbiddenType = 'expired' | 'lowVer' | 'exceed'
export type ForbidCoverType = 'result' | 'alert'

export const preventEventFunc = (
  event: Event,
  prevent: boolean,
  handler: any,
  modal?: string // 目前有'renew' | 'upgrade' | 'popover'
) => {
  if (prevent) {
    if (modal) {
      if (modal === 'renew') {
        const orgStore = useOrgStore()
        const { showRenewModal } = orgStore
        showRenewModal()
      } else if (modal === 'upgrade') {
        const orgStore = useOrgStore()
        const { showUpgradeModal } = orgStore
        showUpgradeModal()
      } else if (modal === 'popover') {
        // 这里不阻止事件冒泡，因为需要弹出pop提示
      }
    } else {
      event.preventDefault()
      event.stopPropagation()
    }
  } else {
    handler()
  }
}
