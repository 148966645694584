import { OrgStatus, OrgVersionColorMap, type Organization } from '@/api/org/basic'
import {
  CONFIG_ITEM_SYSTEM_GUIDE,
  CONFIG_ITEM_SYSTEM_INDEX,
  CONFIG_ITEM_SYSTEM_PRICING,
  CONFIG_TYPE_SYSTEM,
  FEATURE_CONFIG_PATH_KEYS,
  SystemPricingTypes,
  type ConfigPath,
  type FeatureConfigMap,
  type FeatureKey,
  type FeatureVal,
  type PricePlanInfo,
  type SystemConfig,
  type SystemGuideConfig,
  type SystemIndexConfig,
  type SystemPricingConfig,
  type SystemPricingType
} from '@/api/system/config'
import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { mockConfig } from './mock-config'
import { useOrgStore } from './org'

export const useConfigStore = defineStore('config', () => {
  const systemIndexConfig = ref<SystemIndexConfig | null>(null)
  const systemPricingConfig = ref<SystemPricingConfig | null>(null)
  const systemGuideConfig = ref<SystemGuideConfig | null>(null)
  const loaded = ref(false)
  const updateIndexConfig = (config: SystemIndexConfig) => {
    systemIndexConfig.value = config
  }
  const updatePricingConfig = (config: SystemPricingConfig) => {
    const finalConfig = config
    // const finalConfig = mockConfig
    systemPricingConfig.value = finalConfig
    // 更新功能权限配置Map
    refreshFeatureConfig(finalConfig)
  }
  const updateGuideConfig = (config: SystemGuideConfig) => {
    systemGuideConfig.value = config
  }
  const updateConfigs = (configs: SystemConfig<any>[]) => {
    resetConfigs()
    configs
      .filter((conf) => conf.type === CONFIG_TYPE_SYSTEM)
      .forEach((conf) => {
        switch (conf.item) {
          case CONFIG_ITEM_SYSTEM_INDEX:
            updateIndexConfig(conf.value)
            break
          case CONFIG_ITEM_SYSTEM_PRICING:
            updatePricingConfig(conf.value)
            break
          case CONFIG_ITEM_SYSTEM_GUIDE:
            updateGuideConfig(conf.value)
            break
        }
      })
    loaded.value = true
  }
  const resetConfigs = () => {
    systemIndexConfig.value = null
    systemPricingConfig.value = null
    systemGuideConfig.value = null
    loaded.value = false
  }

  const orgStore = useOrgStore()
  const { org } = storeToRefs(orgStore)
  const featureConfig = ref<FeatureConfigMap>(new Map())
  const planMap = ref<Map<SystemPricingType, PricePlanInfo>>(new Map())
  const minVersionMap = ref<Map<ConfigPath, PricePlanInfo>>(new Map())

  const refreshFeatureConfig = (config: SystemPricingConfig) => {
    planMap.value.clear()
    featureConfig.value.clear()
    minVersionMap.value.clear()
    // 最先处理收费配置，统计配置
    config.plans.forEach((plan) => {
      const price = plan.pricePerMonth
      featureConfig.value.set(`PricePerMonth_${plan.code}`, price)
      const statistics = plan.statistics
      featureConfig.value.set(`StatisticsApis_${plan.code}`, statistics)
      if (statistics && !minVersionMap.value.has('StatisticsApis')) {
        minVersionMap.value.set('StatisticsApis', plan)
      }
      planMap.value.set(plan.code, plan)
    })
    // 依次处理比较配置
    config.comparisons.forEach((group) => {
      group.features.forEach((feature) => {
        const path = `${group.group}_${feature.name}`
        const key = FEATURE_CONFIG_PATH_KEYS[path]
        if (!key) {
          return // 略过未知的配置
        }
        const type = feature.type
        let minVersion: PricePlanInfo | undefined = undefined
        SystemPricingTypes.forEach((plan) => {
          const value = feature[plan]
          let val: FeatureVal
          if (type === 'Y/N') {
            val = !!value
            if (minVersion === undefined && val) {
              minVersion = planMap.value.get(plan)
              if (minVersion) {
                minVersionMap.value.set(key, minVersion)
              }
            }
          } else if (type === 'NUM' || type === 'SYS') {
            val = value as number
          } else if (type === 'TXT') {
            val = value as string
          } else {
            val = undefined
          }
          featureConfig.value.set(`${key}_${plan}`, val)
        })
      })
    })
  }

  const versionVal = (key: FeatureKey, argOrg?: Organization): FeatureVal => {
    const orgInfo = argOrg || org.value
    if (!orgInfo || orgInfo.status !== OrgStatus.FORMAL) {
      return undefined
    }
    const version = orgInfo.serviceVersion
    return featureConfig.value.get(`${key}_${version}`)
  }
  const versionExpired = (argOrg?: Organization): boolean => {
    const orgInfo = argOrg || org.value
    if (!orgInfo || orgInfo.status !== OrgStatus.FORMAL) {
      return true
    }
    const expireTime = orgInfo.serviceExpireTime
    if (expireTime) {
      const now = new Date().getTime()
      return now < expireTime.getTime()
    } else {
      return false
    }
  }

  const versionSupports = (key: FeatureKey, argOrg?: Organization): boolean => {
    const orgInfo = (argOrg || org.value) as Organization
    if (versionExpired(orgInfo)) {
      return false
    }
    const version = orgInfo!!.serviceVersion
    const val = featureConfig.value.get(`${key}_${version}`)
    return val === true
  }
  const versionLimit = (key: FeatureKey, argOrg?: Organization): number => {
    const orgInfo = (argOrg || org.value) as Organization
    if (versionExpired(orgInfo)) {
      return 0
    }
    const version = orgInfo!!.serviceVersion
    const val = featureConfig.value.get(`${key}_${version}`)
    if (val === undefined || typeof val !== 'number') {
      return 0
    } else {
      return val as number
    }
  }

  const orgVersionText = (argOrg?: Organization) => {
    const currOrg = argOrg || org.value!!
    if (currOrg.status === OrgStatus.DISSOLVED) {
      return `已解散`
    }
    const name = planMap.value.get(currOrg.serviceVersion)?.name || currOrg.serviceVersion
    if (versionExpired(currOrg)) {
      return `${name}-已过期`
    }
    return name
  }
  const orgVersionColor = (argOrg?: Organization) => {
    const currOrg = argOrg || org.value!!
    if (currOrg.status === OrgStatus.DISSOLVED) {
      return '#00000040'
    }
    if (versionExpired(currOrg)) {
      return 'red'
    }
    return OrgVersionColorMap.get(currOrg.serviceVersion) || 'default'
  }
  return {
    systemIndexConfig,
    systemPricingConfig,
    systemGuideConfig,
    loaded,
    // updateIndexConfig,
    // updatePricingConfig,
    // updateGuideConfig,
    updateConfigs,
    resetConfigs,
    featureConfig,
    planMap,
    minVersionMap,
    versionVal,
    versionExpired,
    versionSupports,
    versionLimit,
    orgVersionText,
    orgVersionColor
  }
})
