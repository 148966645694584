<script lang="ts" setup>
import dayjs from 'dayjs'
import { computed, type PropType } from 'vue'

const prop = defineProps({
  payAmount: {
    type: Number,
    required: true
  },
  totalAmount: {
    type: Number,
    required: true
  },
  deductAmount: {
    type: Number,
    required: false
  },
  expireTime: {
    type: Object as PropType<dayjs.Dayjs>,
    required: false
  }
})

const paIntPart = computed(() => Math.floor(prop.payAmount * 100) / 100)
const paDecPart = computed(() => String(Math.floor(prop.payAmount * 100) % 100).padStart(2, '0'))
const taIntPart = computed(() => Math.floor(prop.totalAmount * 100) / 100)
const taDecPart = computed(() => String(Math.floor(prop.totalAmount * 100) % 100).padStart(2, '0'))
const daIntPart = computed(() => Math.floor(prop.deductAmount ?? 0 * 100) / 100)
const daDecPart = computed(() =>
  String(Math.floor(prop.deductAmount ?? 0 * 100) % 100).padStart(2, '0')
)
const expTimeStr = computed(() =>
  prop.expireTime ? prop.expireTime.format('YYYY-MM-DD') : '长期有效'
)
</script>

<template>
  <a-flex class="area-outer" vertical>
    <a-flex class="area-inner-pa" :align="'flex-end'">
      <span class="pa-desc-text">仅需</span>
      <span class="pa-int-part">￥{{ paIntPart }}.</span>
      <span class="pa-dec-part">{{ paDecPart }}</span>
    </a-flex>
    <a-flex justify="space-between">
      <a-flex :align="'flex-end'" v-if="deductAmount && deductAmount > 0">
        总计：
        <span class="ta-text int-part">￥{{ taIntPart }}.</span>
        <span class="ta-text dec-part">{{ taDecPart }}</span>
      </a-flex>
      <a-flex :align="'flex-end'" v-if="deductAmount && deductAmount > 0">
        当前可抵扣：
        <span class="da-text int-part">￥{{ daIntPart }}.</span>
        <span class="da-text dec-part">{{ daDecPart }}</span>
      </a-flex>
      <a-flex :align="'flex-end'">
        服务期至：
        <span class="time-text">{{ expTimeStr }}</span>
      </a-flex>
    </a-flex>
  </a-flex>
</template>

<style lang="less" scoped>
.area-outer {
  padding: 0 16px;
}

.area-inner-pa {
  margin-bottom: 8px;
}

.pa-desc-text {
  font-size: 18px;
  color: #888;
  padding: 0 8px;
}

.pa-int-part {
  font-size: 22px;
  font-weight: 700;
  color: #1677ff;
}

.pa-dec-part {
  font-size: 18px;
  font-weight: 600;
  color: #1677ff;
}

.int-part {
  font-size: 16px;
  font-weight: 700;
}

.dec-part {
  font-size: 14px;
  font-weight: 600;
}

.ta-text {
  color: #d48806;
}

.da-text {
  color: #52c41a;
}

.time-text {
  font-size: 16px;
  font-weight: 600;
  color: #531dab;
}
</style>
