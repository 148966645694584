<script lang="ts" setup>
import { OrgStatus, type Organization, type OrganizationReq } from '@/api/org/basic'
import type { SystemPricingType } from '@/api/system/config'
import PayAmountDetail from '@/components/org/PayAmountDetail.vue'
import { useConfigStore } from '@/stores/config'
import type { Rule } from 'ant-design-vue/es/form'
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'
import type { PropType } from 'vue'
import { computed, reactive, ref, watch, type RendererElement } from 'vue'
import { monthOptions } from '.'
import type { PaymentInfo } from '../payment'

export interface OrgEditInfo {
  name: string
  description: string
  status: OrgStatus
  plan?: SystemPricingType
  workTimeInHours: number
  workTimeStart?: dayjs.Dayjs
  workTimeEnd?: dayjs.Dayjs
  month?: number
}

const prop = defineProps({
  currentOrg: {
    type: Object as PropType<Organization>,
    required: false
  }
})
const emit = defineEmits(['free', 'charge'])
defineExpose({
  validateOrgInfo: () => {
    return new Promise((resolve, reject) => {
      formRef.value?.validate().then(
        () => {
          const org = {
            name: orgInfo.name,
            description: orgInfo.description,
            status: orgInfo.status,
            workTimeInHours: orgInfo.workTimeInHours,
            workTimeStart: orgInfo.workTimeStart!.format('HH:mmZ'),
            workTimeEnd: orgInfo.workTimeEnd!.format('HH:mmZ')
          } as OrganizationReq
          resolve(org)
        },
        () => reject(null)
      )
    })
  },
  validatePaymentInfo: () => {
    return new Promise((resolve) => {
      if (currentPlan.value?.free) {
        resolve(null)
      }
      const paymentInfo = {
        month: orgInfo.month,
        version: orgInfo.plan
      } as PaymentInfo
      resolve(paymentInfo)
    })
  }
})

const configStore = useConfigStore()
const { systemPricingConfig } = storeToRefs(configStore)
const planOptions = computed(() => {
  return (systemPricingConfig.value?.plans || []).map((plan) => ({
    label: plan.name,
    value: plan.code,
    free: plan.pricePerMonth <= 0,
    pricePerMonth: plan.pricePerMonth,
    disabled: false
  }))
})
const currentPlan = computed(() => {
  return planOptions.value.find((plan) => plan.value === orgInfo.plan)
})
const showPayment = computed(() => {
  if (prop.currentOrg) {
    return false
  }
  if (!currentPlan.value) {
    return false
  } else if (currentPlan.value.free) {
    return false
  } else {
    return true
  }
})

const showWorkTimeWarning = ref(false)
const formRef = ref<RendererElement>()
const orgInfo = reactive<OrgEditInfo>({
  name: '',
  description: '',
  status: OrgStatus.FORMAL,
  plan: 'std',
  workTimeInHours: 0,
  workTimeStart: undefined,
  workTimeEnd: undefined,
  month: undefined
})

watch(
  () => prop.currentOrg,
  (newOrg) => {
    if (newOrg) {
      orgInfo.name = newOrg.name
      orgInfo.description = newOrg.description || ''
      orgInfo.status = newOrg.status
      orgInfo.workTimeInHours = newOrg.workTimeInHours
      orgInfo.workTimeStart = dayjs(newOrg.workTimeStart)
      orgInfo.workTimeEnd = dayjs(newOrg.workTimeEnd)
    }
  },
  { immediate: true }
)

const orgRules: Record<string, Rule[]> = {
  name: [
    { required: true, message: '必须输入团队名称', trigger: 'change' },
    { min: 1, max: 20, message: '团队名称长度必须在1-20之间', trigger: 'blur' }
  ],
  description: [
    // { required: true, message: '必须输入待办详细说明', trigger: 'change' },
    { max: 300, message: '团队简介不能超过300字', trigger: 'blur' }
  ],
  workTimeStart: [{ required: true, message: '必须指定上班时间', trigger: 'change' }],
  workTimeEnd: [
    { required: true, message: '必须指定下班时间', trigger: 'change' },
    {
      validator: (rule, value) => {
        if (!value) {
          return Promise.resolve()
        }
        if (orgInfo.workTimeStart) {
          const endTime = value as dayjs.Dayjs
          const diff = endTime.diff(orgInfo.workTimeStart, 'hour', true)
          if (diff <= 0) {
            return Promise.reject('下班时间必须晚于上班时间')
          } else {
            orgInfo.workTimeInHours = Math.round(diff * 10000) / 10000
            if (diff > 8) {
              showWorkTimeWarning.value = true
            } else {
              showWorkTimeWarning.value = false
            }
            return Promise.resolve()
          }
        }
        return Promise.resolve()
      },
      trigger: 'change'
    }
  ],
  plan: [
    { required: true, message: '必须选择版本', trigger: 'change' },
    {
      validator: (rule, value) => {
        if (value === 'pro') {
          return Promise.reject('获取专业版，请联系客服，感谢支持！')
        } else {
          return Promise.resolve()
        }
      },
      trigger: 'change'
    }
  ],
  month: [
    {
      validator: (rule, value) => {
        if (currentPlan.value?.free) {
          return Promise.resolve()
        } else {
          if (!value) {
            return Promise.reject('必须选择订购时长')
          } else {
            return Promise.resolve()
          }
        }
      },
      trigger: 'change'
    }
  ]
}

const changeOrgPlan = () => {
  if (orgInfo.plan === 'fre') {
    emit('free')
  } else {
    emit('charge')
  }
}
const payAmount = computed<number>(() => {
  if (currentPlan.value) {
    return currentPlan.value.pricePerMonth * (orgInfo.month || 0)
  } else {
    return 0
  }
})
const totalAmount = computed<number>(() => payAmount.value)
const expireTime = computed<dayjs.Dayjs | undefined>(() => {
  if (orgInfo.month) {
    return dayjs().add(orgInfo.month, 'month')
  } else {
    return undefined
  }
})
</script>

<template>
  <a-form
    ref="formRef"
    :model="orgInfo"
    :rules="orgRules"
    :label-col="{ span: 4 }"
    :wrapper-col="{ span: 20 }"
  >
    <a-form-item label="团队名称" name="name">
      <a-input
        v-model:value="orgInfo.name"
        placeholder="请输入团队名称"
        show-count
        :maxlength="20"
      />
    </a-form-item>
    <a-form-item label="团队简介" name="description">
      <a-textarea
        v-model:value="orgInfo.description"
        placeholder="请输入团队简介"
        :auto-size="{ minRows: 2, maxRows: 5 }"
      />
    </a-form-item>
    <a-form-item label="上班时间" name="workTimeStart">
      <a-time-picker
        v-model:value="orgInfo.workTimeStart"
        format="HH:mm"
        placeholder="请指定本团队的上班时间"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item label="下班时间" name="workTimeEnd">
      <a-time-picker
        v-model:value="orgInfo.workTimeEnd"
        format="HH:mm"
        placeholder="请指定本团队的下班时间"
        style="width: 100%"
      />
    </a-form-item>
    <template v-if="showWorkTimeWarning">
      <a-divider />
      <a-alert message="请注意在上下班时间内安排休息，避免每日工作时长超过8小时" type="warning" />
    </template>
    <a-form-item v-if="!currentOrg" label="选择版本" name="plan" required>
      <a-radio-group
        v-model:value="orgInfo.plan"
        option-type="button"
        :options="planOptions"
        @change="changeOrgPlan"
      />
    </a-form-item>
    <a-form-item v-if="showPayment" label="订购时长" name="month" required>
      <a-radio-group v-model:value="orgInfo.month" option-type="button" :options="monthOptions" />
    </a-form-item>
    <a-form-item v-if="showPayment" label="费用详情" name="detail">
      <pay-amount-detail
        :pay-amount="payAmount"
        :total-amount="totalAmount"
        :expire-time="expireTime"
      />
    </a-form-item>
  </a-form>
</template>

<style lang="less" scoped></style>
