<script lang="ts" setup>
import type { Organization } from '@/api/org/basic'
import { ref, watch, type PropType } from 'vue'
import PlanDetailCompare from './PlanDetailCompare.vue'

const prop = defineProps({
  open: {
    type: Boolean,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  contentTitle: {
    type: String,
    required: true
  },
  propOrg: {
    type: Object as PropType<Organization>,
    required: false,
    default: null
  }
})
const emit = defineEmits(['ok', 'close'])

const type = ref<'content' | 'compare'>('content')
watch(
  () => prop.open,
  () => (type.value = 'content')
)
</script>

<template>
  <a-modal
    :open="open"
    :title="title"
    :footer="null"
    :width="1000"
    centered
    :mask-closable="false"
    @cancel="emit('close')"
  >
    <div class="modal-content">
      <a-radio-group v-model:value="type" button-style="solid" class="content-radio">
        <a-radio-button :value="'content'">{{ contentTitle }}</a-radio-button>
        <a-radio-button :value="'compare'">各版本价格&对比</a-radio-button>
      </a-radio-group>
      <div class="content-div" v-show="type === 'content'">
        <slot v-if="open" />
      </div>
      <div class="compare-div" v-show="type === 'compare'">
        <plan-detail-compare :show-btn="false" :prop-org="propOrg" :max-height="500" />
      </div>
    </div>
  </a-modal>
</template>

<style lang="less" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .content-radio {
    margin-bottom: 20px;
  }
}

.content-div {
  width: 100%;
  height: 500px;
  overflow-y: auto;
}
</style>
