<script lang="ts" setup>
import type { Organization } from '@/api/org/basic'
import type { PricePlanInfo } from '@/api/system/config'
import { useConfigStore } from '@/stores/config'
import { useOrgStore } from '@/stores/org'
import { useAccountStore } from '@/stores/user'
import { CheckOutlined, MinusOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue'
import type { ColumnType, ColumnsType } from 'ant-design-vue/es/table'
import { storeToRefs } from 'pinia'
import type { PropType } from 'vue'
import { computed, onMounted, ref } from 'vue'

const prop = defineProps({
  showBtn: {
    type: Boolean,
    required: false,
    default: false
  },
  propOrg: {
    type: Object as PropType<Organization>,
    required: false,
    default: null
  },
  maxHeight: {
    type: Number,
    required: false,
    default: 0
  }
})
const emit = defineEmits(['showCreate', 'showLogin', 'showUpgrade'])

const divRef = ref<HTMLElement>()
const accountStore = useAccountStore()
const { user } = storeToRefs(accountStore)
const orgStore = useOrgStore()
const { org } = storeToRefs(orgStore)
onMounted(() => {
  if (prop.maxHeight && divRef.value) {
    divRef.value.style.maxHeight = `${prop.maxHeight}px`
    divRef.value.style.overflowY = 'auto'
    divRef.value.style.scrollbarWidth = 'none'
  }
})

const currentOrg = computed(() => {
  return prop.propOrg || org.value
})
const emitShowCreate = () => {
  emit('showCreate')
}
const emitShowLogin = () => {
  emit('showLogin')
}
const emitShowUpgrade = () => {
  emit('showUpgrade')
}

const configStore = useConfigStore()
const { systemPricingConfig } = storeToRefs(configStore)
const colSpan = (systemPricingConfig.value?.plans.length || 0) + 1
const checkIconStyle = { color: '#87d068', fontSize: '18px' }
const minusIconStyle = { color: '#9d9d9d', fontSize: '18px' }

const column0: ColumnType = {
  title: '功能特性',
  dataIndex: 'name',
  key: 'name',
  width: '40%',
  customCell: (record) => {
    if (record.group) {
      return { colSpan, style: { color: '#888', fontWeight: '600', backgroundColor: '#fafafa' } }
    }
    return {}
  }
}
const columns = ref<ColumnsType>(
  [column0].concat(
    systemPricingConfig.value?.plans.map((plan) => {
      return {
        title: plan.name,
        dataIndex: plan.code,
        key: plan.code,
        align: 'center',
        width: `${60 / (systemPricingConfig.value?.plans.length || 1)}%`,
        customCell: (record) => {
          if (record.group) {
            return { colSpan: 0 }
          }
          return { style: { fontWeight: 400 } }
        }
      } as ColumnType
    }) || []
  )
)
const dataSource = ref(
  (() => {
    const featureDatas: any[] = []
    systemPricingConfig.value?.comparisons.forEach((group) => {
      const groupData: any = {
        name: group.group,
        group: true
      }
      systemPricingConfig.value?.plans.forEach((plan) => (groupData[plan.code] = null))
      featureDatas.push(groupData)
      group.features.forEach((feature) => {
        if (feature.type === 'SYS') {
          return
        }
        const featureData = {
          ...feature,
          group: false
        }
        featureDatas.push(featureData)
      })
    })
    return featureDatas
  })()
)

interface OrderBtnInfo {
  free: boolean
  owner: boolean
  current: boolean
  type: string
  disabled: boolean
  text: string
  onClick: () => void
}

const orderBtnInfo = (plan: PricePlanInfo): OrderBtnInfo => {
  const free = plan.pricePerMonth === 0
  const index = systemPricingConfig.value?.plans.findIndex((p) => p.code === plan.code)
  const currentIndex = systemPricingConfig.value?.plans.findIndex(
    (p) => p.code === currentOrg.value?.serviceVersion
  )
  if (index === undefined || currentIndex === undefined) {
    return {
      free,
      owner: false,
      current: false,
      type: 'default',
      disabled: false,
      text: '立即订购',
      onClick: emitShowLogin
    }
  }
  if (user.value) {
    if (currentOrg.value) {
      if (currentIndex < index) {
        // 暂时允许所有人升级，并不限制只有所有者才可以升级
        return {
          free,
          owner: currentOrg.value.ownerId === user.value.id,
          current: false,
          type: 'primary',
          disabled: false,
          text: '立即升级',
          onClick: emitShowUpgrade
        }
      } else if (currentIndex === index) {
        return {
          free,
          owner: currentOrg.value.ownerId === user.value.id,
          current: true,
          type: 'default',
          disabled: true,
          text: '当前版本',
          onClick: () => {}
        }
      } else {
        return {
          free,
          owner: currentOrg.value.ownerId === user.value.id,
          current: false,
          type: 'default',
          disabled: true,
          text: '已有更高版本',
          onClick: () => {}
        }
      }
    } else {
      return {
        free,
        owner: false,
        current: false,
        type: 'primary',
        disabled: false,
        text: free ? '立即使用' : '立即订购',
        onClick: emitShowCreate
      }
    }
  } else {
    return {
      free,
      owner: false,
      current: false,
      type: 'primary',
      disabled: false,
      text: free ? '立即注册' : '立即订购',
      onClick: emitShowLogin
    }
  }
}
</script>

<template>
  <div class="compare-content-div" ref="divRef">
    <a-flex justify="space-between" gap="middle" v-if="systemPricingConfig">
      <a-card class="plan-card" v-for="(plan, index) in systemPricingConfig.plans" :key="index">
        <div style="font-size: 18px; font-weight: 700; padding-bottom: 8px">
          {{ plan.name }}
        </div>
        <div style="height: 48px; font-size: 14px; color: #9d9d9d; padding-bottom: 8px">
          {{ plan.desc }}
        </div>
        <template v-if="plan.pricePerMonth > 0">
          <a-flex :vertical="true" justify="center" :align="'center'">
            <a-flex justify="center" :align="'baseline'" gap="small" class="plan-card-price-box">
              <div style="font-size: 22px; font-weight: 700; color: #00a1f1">
                {{ plan.pricePerMonth / 100 }}
              </div>
              <div class="plan-card-price-desc">元/月</div>
            </a-flex>
            <a-flex justify="center" :align="'baseline'">
              <div class="plan-card-price-desc">（低至</div>
              <div style="font-weight: 700; color: #ffbb00">
                {{
                  Math.round(
                    plan.pricePerMonth /
                      30 /
                      (systemPricingConfig.comparisons
                        .find((g) => g.group === '团队&成员')!
                        .features.find((f) => f.name === '成员人数')![plan.code]! as number)
                  ) / 100
                }}
              </div>
              <div class="plan-card-price-desc">元/人/日）</div>
            </a-flex>
          </a-flex>
        </template>
        <template v-else-if="plan.pricePerMonth === 0">
          <a-flex :vertical="true" justify="center" :align="'center'">
            <a-flex
              :align="'baseline'"
              class="plan-card-price-box"
              style="font-size: 20px; font-weight: 700; color: #7cbb00"
            >
              完全免费
            </a-flex>
            <a-flex justify="center" :align="'baseline'" class="plan-card-price-desc">
              （{{
                systemPricingConfig.comparisons
                  .find((g) => g.group === '团队&成员')!
                  .features.find((f) => f.name === '成员人数')![plan.code]! as number
              }}人以下团队免费）
            </a-flex>
          </a-flex>
        </template>
        <template v-else>
          <a-flex :vertical="true" justify="center" :align="'center'">
            <a-flex
              :align="'baseline'"
              class="plan-card-price-box"
              style="font-size: 20px; font-weight: 700; color: #783bd2"
            >
              咨询客服报价
            </a-flex>
            <a-flex justify="center" :align="'baseline'" class="plan-card-price-desc">
              （请添加客服微信咨询）
            </a-flex>
          </a-flex>
        </template>
        <div style="text-align: center" v-if="showBtn">
          <a-button
            :type="orderBtnInfo(plan).type"
            class="buy-btn"
            :disabled="orderBtnInfo(plan).disabled"
            @click="orderBtnInfo(plan).onClick"
          >
            {{ orderBtnInfo(plan).text }}
          </a-button>
        </div>
        <a-divider style="margin-bottom: 12px" />
        <a-flex :vertical="true" gap="small">
          <div style="padding-bottom: 4px; font-weight: 600">特色 & 特点：</div>
          <div v-for="(hl, index) in plan.highlights" :key="index" class="plan-card-price-desc">
            <div class="mark">-</div>
            <div class="text">{{ hl }}</div>
          </div>
        </a-flex>
      </a-card>
    </a-flex>
    <a-divider v-if="systemPricingConfig" />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      bordered
      :scroll="{ y: `${maxHeight > 100 ? maxHeight - 50 : 500}px` }"
      :pagination="false"
      style="width: 100%; border-radius: 16px"
      v-if="systemPricingConfig"
    >
      <template #bodyCell="{ text, record, column }">
        <template v-if="column.dataIndex !== 'name'">
          <template v-if="record.type === 'Y/N'">
            <CheckOutlined :style="checkIconStyle" v-if="text" />
            <MinusOutlined :style="minusIconStyle" v-else />
          </template>
          <template v-else-if="record.type === 'NUM'">
            {{ text >= 0 ? text : '不限' }}
          </template>
          <template v-else>{{ text }}</template>
        </template>
        <template v-else>
          <span :class="{ 'desc-title': !record.group }">
            {{ text }}
            <a-tooltip class="desc-tooltip" :title="record.desc" v-if="record.desc">
              <QuestionCircleOutlined />
            </a-tooltip>
          </span>
        </template>
      </template>
    </a-table>
  </div>
</template>

<style lang="less" scoped>
.compare-content-div::-webkit-scrollbar {
  display: none;
}

.plan-card {
  flex: 1 1 25%;

  :deep(.ant-card-body) {
    padding: 12px 18px;
  }
}

.plan-card-price-desc {
  display: flex;
  font-size: 12px;
  color: #9d9d9d;

  .mark {
    width: 12px;
    text-align: center;
  }

  .text {
    flex: 1;
  }
}

.buy-btn {
  width: 50%;
  margin-top: 12px;
}

.plan-card-price-box {
  height: 46px;
  padding: 8px 0;
}

.desc-tooltip {
  padding-left: 8px;
}

.desc-title {
  padding: 0 12px;
}
</style>
