import { fetchOrgDetail, type Organization } from '@/api/org/basic'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useOrgStore = defineStore('org', () => {
  // 暂时把org放入sessionStorage中，避免出现刷新页面后org丢失的情况
  const org = ref<Organization | null>(null)
  const orgList = ref<Organization[] | null>(null)
  const loadOrgFromStorage = (currentOrgId: number) => {
    const sessionOrg = sessionStorage.getItem('org')
    if (sessionOrg) {
      org.value = JSON.parse(sessionOrg)
    } else {
      org.value =
        orgList.value?.find((item) => item.id === currentOrgId) || orgList.value?.[0] || null
    }
  }
  const changeOrg = (currentOrg: Organization) => {
    org.value = currentOrg
    sessionStorage.setItem('org', JSON.stringify(currentOrg))
  }
  const resetOrg = () => {
    org.value = null
    sessionStorage.removeItem('org')
  }
  const setOrgList = (orgs: Organization[]) => {
    orgList.value = orgs
    // 更新sessionStorage中的org
    if (org.value) {
      const newOrg = orgs.find((item) => item.id === org.value!.id)
      if (newOrg) {
        changeOrg(newOrg)
      }
    }
  }
  const resetOrgList = () => {
    orgList.value = null
  }

  const updateOrgInfo = () => {
    // 更新组织信息，包括org和orgList
    fetchOrgDetail(org.value!.id).then((res) => {
      const newOrgList = (orgList.value || []).map((item) =>
        item.id === res.data.id ? res.data : item
      )
      changeOrg(res.data)
      setOrgList(newOrgList)
    })
  }
  const renewModalOpen = ref(false)
  const showRenewModal = () => {
    renewModalOpen.value = true
  }
  const hideRenewModal = () => {
    renewModalOpen.value = false
  }
  const renewSuccess = () => {
    updateOrgInfo()
    hideRenewModal()
  }
  const upgradeModalOpen = ref(false)
  const showUpgradeModal = () => {
    upgradeModalOpen.value = true
  }
  const hideUpgradeModal = () => {
    upgradeModalOpen.value = false
  }
  const upgradeSuccess = () => {
    updateOrgInfo()
    hideUpgradeModal()
  }

  return {
    org,
    orgList,
    loadOrgFromStorage,
    changeOrg,
    resetOrg,
    setOrgList,
    resetOrgList,

    renewModalOpen,
    showRenewModal,
    hideRenewModal,
    renewSuccess,
    upgradeModalOpen,
    showUpgradeModal,
    hideUpgradeModal,
    upgradeSuccess
  }
})
