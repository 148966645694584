<script lang="ts" setup>
import { fetchOrgUpgradeInfo, type Organization } from '@/api/org/basic'
import PayAmountDetail from '@/components/org/PayAmountDetail.vue'
import { useConfigStore } from '@/stores/config'
import { message } from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form'
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'
import {
  computed,
  nextTick,
  reactive,
  ref,
  watchEffect,
  type PropType,
  type RendererElement
} from 'vue'
import { monthOptions } from '.'
import type { PaymentInfo } from '../payment'
import { useRequest } from 'vue-request'

const prop = defineProps({
  org: {
    type: Object as PropType<Organization>,
    required: true
  }
})
defineExpose({
  validateInfo: () => {
    return new Promise((resolve, reject) => {
      formRef.value?.validate().then(
        () => {
          if (!amountLoading.value && amountData.value) {
            resolve(upgradeInfo)
          } else {
            reject(null)
          }
        },
        () => reject(null)
      )
    })
  }
})

const formRef = ref<RendererElement>()
const upgradeInfo = reactive<PaymentInfo>({
  orgId: prop.org.id,
  month: 1,
  version: 'std',
  amount: 0
})
const upgradeRules: Record<string, Rule[]> = reactive({
  version: [
    { required: true, message: '请选择版本', trigger: 'change' },
    {
      validator: (rule, value) => {
        if (value === 'pro') {
          return Promise.reject('获取专业版，请联系客服，感谢支持！')
        } else {
          // 防止降级
          const index = planOptions.value.findIndex((plan) => plan.value === value)
          if (index < matchIndex.value) {
            return Promise.reject('请升级到更高版本')
          } else {
            return Promise.resolve()
          }
        }
      },
      trigger: 'change'
    }
  ],
  month: [{ required: true, message: '请选择续订时长', trigger: 'change' }]
})

const configStore = useConfigStore()
const { systemPricingConfig } = storeToRefs(configStore)
const matchIndex = computed(
  () =>
    (systemPricingConfig.value?.plans || []).findIndex(
      (plan) => plan.code === prop.org.serviceVersion
    ) ?? Infinity
)
const planOptions = computed(() => {
  return (systemPricingConfig.value?.plans || [])
    .filter((_, index) => matchIndex.value < index)
    .map((plan) => {
      return {
        label: plan.name,
        value: plan.code,
        free: plan.pricePerMonth <= 0,
        pricePerMonth: plan.pricePerMonth
      }
    })
})

const payAmountRef = ref(0)
const totalAmountRef = ref(0)
const deductAmountRef = ref(0)
const expireTimeRef = ref<dayjs.Dayjs>(dayjs())
const onPlanChange = () => loadAmountRun()
const onMonthChange = () => loadAmountRun()

watchEffect(() => {
  upgradeInfo.orgId = prop.org.id
  // 监听传入的团队信息，要求新的版本必须高于当前版本
  nextTick(() => {
    upgradeInfo.version = planOptions.value[0].value
    loadAmountRun()
  })
})

const {
  data: amountData,
  loading: amountLoading,
  run: loadAmountRun
} = useRequest(
  async () => {
    try {
      const resp = await fetchOrgUpgradeInfo(prop.org.id, upgradeInfo.month, upgradeInfo.version)
      const { endTime, totalAmount, deductAmount, payAmount } = resp.data
      payAmountRef.value = payAmount
      totalAmountRef.value = totalAmount
      deductAmountRef.value = deductAmount
      expireTimeRef.value = dayjs(endTime)
      return true
    } catch (err: any) {
      message.error(err.response?.data.message || '获取费用信息失败')
      payAmountRef.value = 0
      totalAmountRef.value = 0
      deductAmountRef.value = 0
      expireTimeRef.value = dayjs()
      return false
    }
  },
  { manual: true }
)
</script>

<template>
  <a-form
    ref="formRef"
    :model="upgradeInfo"
    :rules="upgradeRules"
    :label-col="{ span: 4 }"
    :wrapper-col="{ span: 20 }"
  >
    <a-form-item label="选择版本" name="version" required>
      <a-radio-group
        v-model:value="upgradeInfo.version"
        option-type="button"
        :options="planOptions"
        @change="onPlanChange"
      />
    </a-form-item>
    <a-form-item label="订购时长" name="month" required>
      <a-radio-group
        v-model:value="upgradeInfo.month"
        option-type="button"
        :options="monthOptions"
        @change="onMonthChange"
      />
    </a-form-item>
    <a-form-item label="费用详情" name="detail">
      <pay-amount-detail
        :pay-amount="payAmountRef"
        :total-amount="totalAmountRef"
        :deduct-amount="deductAmountRef"
        :expire-time="expireTimeRef"
      />
    </a-form-item>
  </a-form>
</template>

<style lang="less" scoped></style>
